export const PageRoutesToModule = {
    '': { module: 'basic', title: 'Dashboard' },
    Documentation: { module: 'basic' },
    facilities: { module: 'basic', title: 'Facilities' },
    cells: { module: 'basic', title: 'Cells' },
    equipment: { module: 'basic', title: 'Equipment' },
    products: { module: 'basic', title: 'Products' },
    monitor_live_data: { module: 'basic', title: 'Signal Data' },
    monitor_rules: { module: 'controls', title: 'Rules Control' },
    monitor_machine_learning: { module: 'controls', title: 'ML Control' },
    historical_runs: { module: 'controls', title: 'Historical Runs' },
    live_services: { module: 'controls', title: 'Live Services' },
    graphs_charts: { module: 'reporting', title: 'Reporting - Run Reports' },
    impact_kpi: { module: 'reporting', title: 'Reporting - Impact KPI' },
    rules_configuration: { module: 'controls', title: ' Rule Configuration' },
    ml_configuration: { module: 'controls', title: 'ML Configuration' },
    dpk: { module: 'controls', title: 'DPK' },
    lpp: { module: 'controls', title: 'LPP' },
    lcp_trained: { module: 'controls', title: 'LCP' },
    backtest: { module: 'controls', title: 'Back Test' },
    ghost_scheduler: { module: 'controls', title: 'Ghost Scheduler' },
    lcp_performance: { module: 'controls', title: 'LCP Performance' },
    monitor: { module: 'alarms', title: 'Predictive Alarms - Monitor' },
    monitor_stack: { module: 'basic', title: 'Monitor Stack' },
    lcp_performance_details: { module: 'controls', title: 'LCP Performance' },
    contact_us: { module: 'basic', title: 'Contact Us' },
    ml_controller_dashboard: { module: 'basic' , title: ' / ML Controller / Dashboard'},
    ml_controller_charts: { module: 'basic' , title: ' / ML Controller / Charts'},
    ml_controller_summary: { module: 'basic' , title: ' / ML Controller / Summary'},
    ml_predictor_dashboard: { module: 'basic' , title: ' / ML Predictor / Dashboard'},
    ml_predictor_charts: { module: 'basic' , title: ' / ML Predictor / Charts'},
    ml_predictor_summary: { module: 'basic' , title: ' / ML Predictor / Summary'},
    rules_controller_dashboard: { module: 'basic' , title: ' / Rules Controller / Dashboard'},
    rules_controller_charts: { module: 'basic' , title: ' / Rules Controller / Charts'},
    training_wheels_dashboard: { module: 'basic' , title: ' / Training Wheels / Dashboard'},
    training_wheels_charts: { module: 'basic' , title: ' / Training Wheels / Charts'},
    training_wheels_summary: { module: 'basic' , title: ' / Training Wheels / Summary'},
    matrix_controller_dashboard: { module: 'basic' , title: ' / Training Wheels / Dashboard'},
    matrix_controller_output: { module: 'basic' , title: ' / Training Wheels / Output'},
    matrix_controller_equipment: { module: 'basic' , title: ' / Training Wheels / Equipment'},
};
