import React, { lazy } from "react";

const MlConfiguration = lazy(() => import('./ml-configuration'))
const RulesConfiguration = lazy(() => import('./rules-configuration'))

const ControlsRoute = () => {
    return [
    {
        key: 'rules_configuration',
        path: '/controls_admin/rules_configuration',
        component: <RulesConfiguration />
    },
    {
        key: 'ml_configuration',
        path: '/controls_admin/ml_configuration',
        component: <MlConfiguration />
    }]
}

export default ControlsRoute;